<template>
  <div class="buyer bj">
    <div class="title">
      <div class="form jn-form-style">
        <div class="form-item btn-wrap">
          <el-button
            type="primary"
            style="margin-left: 5px"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <el-button @click="onHandleCreate" type="primary">新 增</el-button>
        </div>
        <div class="form-item">
          <el-select
            placeholder="选择集配中心"
            v-model="formData.logistic_business_id"
            @change="onChangeLogistic"
            clearable
          >
            <el-option
              v-for="item of logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            class="jn-input-style multiple"
            placeholder="选择城市仓"
            :loading="loadingCityStore"
            v-model="formData.store_logistics_id"
            collapse-tags
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.fullname"
            placeholder="请输入业务员名称"
            clearable
          ></el-input>
        </div>

        <div class="form-item">
          <el-input
            v-model="formData.mobile"
            placeholder="请输入联系电话"
            clearable
          ></el-input>
        </div>

        <div class="form-item">
          <el-select
            v-model="formData.state"
            clearable
            placeholder="请选择状态"
          >
            <el-option label="全部状态" value=""></el-option>
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="2"></el-option>
          </el-select>
        </div>

        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button type="primary" @click="buyerlistexport">导 出</el-button>
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="logisticsName" align="center" label="集配中心">
          <template slot-scope="scope">
            {{ scope.row.logistic_business_name || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="buyer_type_txt" align="center" label="业务类型">
        </el-table-column>
        <el-table-column prop="logistics_name" align="center" label="城市仓">
          <!-- 只在地采类型展示城市仓 start -->
          <template slot-scope="scope">
            {{ scope.row.logistics_name }}
          </template>
          <!-- 只在地采类型展示城市仓 end -->
        </el-table-column>

        <el-table-column prop="fullname" align="center" label="业务员姓名">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.fullname) }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="联系电话">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.mobile) }}
          </template>
        </el-table-column>
        <el-table-column prop="categories_name" align="center" label="负责品类">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.categories_name) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="supplier_count"
          align="center"
          label="对接供货商数量"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.supplier_count) }}
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">启用</el-tag>
            <el-tag type="warning" v-else>禁用</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="supplierHandle(scope.$index, scope.row, 'edit')"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
              >{{ scope.row.state == 2 ? "启用" : "禁用" }}</el-button
            >
            <el-button
              type="text"
              @click="supplierHandle(scope.$index, scope.row, 'detail')"
              >查看供货商</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 供货商列表 -->
    <div class="enterprise personindex">
      <el-dialog
        :center="true"
        @close="tableVisible = false"
        :visible="tableVisible"
        :title="title"
        width="70%"
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <el-button @click="onHandleBuyer" :disabled="!supplier_ids.length"
            >数据迁移</el-button
          >
          <div style="display: flex; align-items: center">
            <div class="inputs mr10">
              <el-input
                v-model="formChildData.title"
                placeholder="请输入供应商名称"
                clearable
              ></el-input>
            </div>
            <el-button
              @click="searchSupplier"
              type="primary"
              icon="el-icon-search"
              >查询</el-button
            >
          </div>
        </div>

        <div
          class="row-box d-flex flex-column mt-20"
          style="height: calc(100% - 64px)"
        >
          <el-table
            :data="listData"
            height="100%"
            :border="true"
            :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
            ref="multipleTable"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              type="index"
              width="100px"
              align="center"
              label="序号"
            >
            </el-table-column>
            <el-table-column prop="title" align="center" label="供货商名称">
              <template slot-scope="scope">
                {{ $empty.empty(scope.row.title) }}
              </template>
            </el-table-column>
            <el-table-column prop="stall" align="center" label="档口位置">
              <template slot-scope="scope">
                {{ $empty.empty(scope.row.stall) }}
              </template>
            </el-table-column>
            <el-table-column prop="fullname" align="center" label="联系人">
              <template slot-scope="scope">
                {{ $empty.empty(scope.row.fullname) }}
              </template>
            </el-table-column>
            <el-table-column prop="mobile" align="center" label="联系电话">
              <template slot-scope="scope">
                {{ $empty.empty(scope.row.mobile) }}
              </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="对接业务">
              <template slot-scope="scope" v-if="scope.row.buyer">
                {{ $empty.empty(scope.row.buyer.fullname) }}
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="onHandleBuyer(scope.row)"
                  >数据迁移</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="pagination-position">
            <el-pagination
              type="primary"
              background
              @size-change="handleBuyerChange"
              @current-change="handleBuyerCurrentChange"
              :current-page="currentlistPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="listtotal"
            >
            </el-pagination>
          </div>
        </div>
        <template #footer>
          <el-button @click="tableVisible = false">返回</el-button>
        </template>
      </el-dialog>
    </div>
    <el-dialog
      title="数据迁移"
      :visible.sync="buyerDialogVisible"
      v-if="buyerDialogVisible"
      width="40%"
      class="dialog"
      center
    >
      <div class="content">
        <el-select
          v-model="buyer_id"
          placeholder="请选择数据将要迁移到的业务员姓名"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.fullname + '(' + item.mobile + ')'"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="buyerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onHandleChose">确认</el-button>
      </span>
    </el-dialog>
    <!-- 新增/编辑业务员 start -->
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="40%"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          :rules="rulesForm"
          ref="ruleForm"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item label="业务类型" prop="buyer_type">
            <el-select
              v-model="ruleForm.buyer_type"
              clearable
              placeholder="请选择业务类型"
            >
              <el-option
                v-for="item of buyWayList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="集配中心" prop="logistic_business_id">
            <div>
              <el-select
                placeholder="请选择集配中心"
                :disabled="title != '新增业务员'"
                v-model="ruleForm.logistic_business_id"
                @change="onChangeLogisticDialog"
                clearable
              >
                <el-option
                  v-for="item of logisticsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item
            label="城市仓"
            prop="store_logistics_id"
            v-if="ruleForm.buy_way === BUY_WAY_ENUM3.ORIGIN.value"
          >
            <div>
              <el-select
                placeholder="请选择城市仓"
                 :disabled="title != '新增业务员'"
                :loading="loadingCityStore"
                v-model="ruleForm.store_logistics_id"
                filterable
                clearable
              >
                <el-option
                  v-for="item of cityStoreDialogList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="业务员姓名" prop="fullname">
            <div>
              <el-input
                v-model="ruleForm.fullname"
                placeholder="请输入业务员姓名"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="联系电话" prop="mobile">
            <div>
              <el-input
                v-model="ruleForm.mobile"
                maxLength="11"
                placeholder="请输入联系电话"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="负责品类" prop="categories_name">
            <div class="categories_name_disabled">
              <el-input
                v-model="ruleForm.categories_name"
                placeholder="请输入负责品类"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item v-if="title != '新增业务员'" label="对接供应商数量">
            <div>
              <el-input
                :disabled="true"
                v-model="supplier_count"
                placeholder="请输入对接供应商数量"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="首衡集配分享账号">
            <div>
              <el-input
                v-model="ruleForm.share_member_mobile"
                placeholder="请输入首衡集配分享账号"
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onHandleSubmit">确认</el-button>
      </span>
    </el-dialog>
    <!-- 新增/编辑业务员 end -->
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import { BUY_WAY_ENUM3 } from "@/views/general/utils/enum";
import { buyerexport } from "@/api/export/index";
import { downLoadFlowBlob } from "@/utils/tools/base";
export default {
  name: "buyer",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      tableVisible: false,
      buyerDialogVisible: false,
      supplier_count: "",
      BUY_WAY_ENUM3,
      loadingCityStore: false, // 城市仓加载loading
      buyWayList: Object.values(BUY_WAY_ENUM3), // 业务类型下拉
      cityStoreList: [], // 城市仓列表
      cityStoreDialogList: [], // 弹窗内，城市仓列表
      formData: {
        buy_way: 1, // 业务类型
        buyer_type: 1, //类型 1地采 2招商 3买手
        logistic_business_id: "", // 集配中心ID
        store_logistics_id: "", // 城市仓ID
        fullname: "", // 业务员姓名
        mobile: "", // 联系电话
        state: "", // 状态
        page: 1,
        pageSize: 50,
      }, // 搜索条件
      formDataClone: {}, // 复制一份，用于初始化
      formChildData: {
        buyer_id: "",
        title: "",
        page: 1,
        pageSize: 10,
      }, // 新增业务表单
      tableData: [],
      listData: [],
      supplier_id: "",
      supplier_count: "",
      currentPage: 1,
      currentlistPage: 1,
      total: 0,
      listtotal: 0,
      ruleForm: {
        buy_way: 1, // 业务类型
        buyer_type: 1, // 类型 1地采 2招商 3买手
        logistic_business_id: "", // 集配中心ID
        store_logistics_id: "", // 城市仓ID
        fullname: "",
        mobile: "",
        categories_name: "",
        share_member_mobile:'',
        name: "",
        category_ids: [],
      }, // 新增业务员表单信息
      ruleFormClone: {}, // 复制一份，用于初始化
      title: "",
      centerDialogVisible: false,
      rulesForm: {
        buyer_type: [
          {
            required: true,
            message: "请选择业务类型",
            trigger: ["blur", "change"],
          },
        ],
        logistic_business_id: [
          {
            required: true,
            message: "请选择集配中心",
            trigger: ["blur", "change"],
          },
        ],
        store_logistics_id: [
          {
            required: true,
            message: "请选择城市仓",
            trigger: ["blur", "change"],
          },
        ],
        fullname: [
          { required: true, message: "请输入业务员姓名", trigger: "blur" },
        ],
        mobile: [
          {
            required: true,
            message: "请输入联系电话",
            // blur和change事件触发检验
            trigger: ["blur", "change"],
          },
          // 正则判断为字母或数字
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            // 正则检验前先将值转为字符串
            transform(value) {
              return String(value);
            },
            message: "该手机号码不符合规范",
            trigger: ["blur"],
          },
        ],
        categories_name: [
          { required: true, message: "请输入负责品类", trigger: "change" },
        ],
      },
      options: [],
      buyer_id: "",
      supplier_ids: [],
      list: [],
      list1: [],
      // 集配中心列
      logisticsList: [],
    };
  },
  watch: {
    /**
     * 监听业务类型是否修改，改动后，变更城市仓是否必填属性
     */
    "ruleForm.buy_way"(val) {
      // const res = val === BUY_WAY_ENUM3.ORIGIN.value;
      // this.rulesForm.store_logistics_id[0].required = res;
    },
  },
  mounted() {
    this.formDataClone = cloneDeep(this.formData);
    this.ruleFormClone = cloneDeep(this.ruleForm);
    this.getTableData();
    this.getAjaxLogisticsList();
    this.ajaxGetCityStoreList();
  },
  methods: {
    //导出
    buyerlistexport() {
      let params = {
        ...this.formData,
      };
      delete params.page;
      delete params.pageSize;

      buyerexport(params).then((res) => {
        console.log(res);
        downLoadFlowBlob(res, res?.filename);
      });
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    async ajaxGetCityStoreList() {
      this.loadingCityStore = true;
      const params = {
        logisticBusinessId: this.formData.logistic_business_id,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        // 首次，给弹窗内的城市仓列表赋值
        if (!this.formData.logistic_business_id) {
          this.cityStoreDialogList = cloneDeep(data.data);
        }
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        this.loadingCityStore = false;
      }
    },
    /**
     * 获取城市仓列表（弹窗内）
     */
    async ajaxGetCityStoreDialogLList() {
      this.loadingCityStore = true;
      const params = {
        logisticBusinessId: this.ruleForm.logistic_business_id,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        //接口请求慢第一个接口会覆盖第二次请求数据判断
        let business_const = 0;
        if (data.data.length > 0) {
          data.data.forEach((el) => {
            if (el.business_id == this.ruleForm.logistic_business_id) {
              business_const = 1;
              return;
            }
          });
          if (business_const == 1) {
            this.cityStoreDialogList = data.data;
          }
        } else {
          this.cityStoreDialogList = data.data;
        }
        //接口请求慢第一个接口会覆盖第二次请求数据判断
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        this.loadingCityStore = false;
      }
    },
    /**
     * 修改了集配中心
     */
    onChangeLogistic() {
      this.formData.store_logistics_id = "";
      this.cityStoreList = [];
      this.ajaxGetCityStoreList();
    },
    /**
     * 修改了集配中心（弹窗内）
     */
    onChangeLogisticDialog() {
      this.ruleForm.store_logistics_id = "";
      this.cityStoreDialogList = [];
      this.ajaxGetCityStoreDialogLList();
    },
    /**
     * 触发数据迁移按钮
     */
    onHandleBuyer(row) {
      this.buyerDialogVisible = true;
      this.getAjaxBuyerList();
      if (row?.id) {
        this.supplier_ids = [];
        this.supplier_ids.push(row.id);
      }
    },
    /**
     * 获取业务人员列表
     */
    async getAjaxBuyerList() {
      const params = {
        buyer_id: this.buyer_id,
      };
      try {
        const { data } = await this.$api.general.buyerAll(params);
        this.options = data;
      } catch (err) {
        console.log("ajax getAjaxBuyerList err", err);
      }
    },
    searchSupplier() {
      this.formChildData.page = 1;
      this.currentlistPage = 1;
      this.buyerSupplierList();
    },
    /**
     * 搜索了列表数据
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },
    /**
     * 新增业务员
     */
    onHandleCreate() {
      this.ruleForm = {
        ...this.ruleFormClone,
      };
      this.centerDialogVisible = true;
      this.title = "新增业务员";
    },
    /**
     * 新增业务员，确认
     */
    onHandleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        const params = {
          ...this.ruleForm,
        };
        delete params.name;
        // 如果选择了基采，则删除城市仓数据
        if (this.ruleForm.buy_way !== BUY_WAY_ENUM3.ORIGIN.value) {
          params.store_logistics_id = "";
          // delete params.store_logistics_id;
        }
        if (valid) {
          this.tool.debounce(async () => {
            if (this.title == "新增业务员") {
              // console.log("🆒 onHandleSubmit", form);
              try {
                await this.$api.general.addbBuyer(params);
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
                setTimeout(() => {
                  this.centerDialogVisible = false;
                  this.onHandleRefresh();
                }, 800);
              } catch (err) {}
            } else if (this.title == "编辑") {
              try {
                await this.$api.general.updateBuyer(params);
                this.$message({
                  type: "success",
                  message: "已修改",
                });
                setTimeout(() => {
                  this.centerDialogVisible = false;
                  this.onHandleRefresh();
                }, 800);
              } catch (err) {}
            }
          });
        }
      });
    },
    /**
     * 重置表单
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.currentPage = 1;
      this.getTableData();
    },
    /**
     * 数据迁移勾选成员
     */
    handleSelectionChange(val) {
      // this.multipleSelection = val;
      this.supplier_ids = [];
      console.log(val);
      val.forEach((item) => {
        this.supplier_ids.push(item.id);
      });
    },
    /**
     * 数据迁移确认
     */
    onHandleChose() {
      // console.log(this.buyer_id, this.supplier_ids);
      if (this.buyer_id) {
        this.tool.debounce(() => {
          this.$api.general
            .changeBuyer({
              supplier_ids: this.supplier_ids,
              buyer_id: this.buyer_id,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "迁移成功",
              });
            });
          setTimeout(() => {
            this.buyerDialogVisible = false;
            this.tableVisible = false;
            this.onHandleRefresh();
          }, 800);
        });
      } else {
        this.$message({
          type: "error",
          message: "请选择业务员",
        });
      }
    },
    /**
     * 编辑/查看供货商
     */
    supplierHandle(index, row, word) {
      switch (word) {
        case "edit":
          this.title = "编辑";
          let data = this.tool.DeepCopy({}, row);
          for (let key in this.ruleForm) {
            this.ruleForm[key] = data[key];
          }
          this.ruleForm.id = row.id;
          this.supplier_count = row.supplier_count;
          this.centerDialogVisible = true;
          break;
        case "detail":
          this.title = "查看供货商";
          this.listData = [];
          this.formChildData.buyer_id = this.buyer_id = row.id;
          // 重置分页参数
          this.formChildData.page = 1;
          this.formChildData.pageSize = 10;
          this.buyerSupplierList();
          this.tableVisible = true;
          break;
      }
    },
    buyerSupplierList() {
      this.$api.general.buyerSupplierList(this.formChildData).then((res) => {
        console.log(res, "获取数据");
        this.listData = res.data.data;
        this.listtotal = res.data.total;
      });
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.formData.page = val;
      this.currentPage = val;
      this.getTableData();
    },
    handleBuyerChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.formChildData.pageSize = val;
      this.buyerSupplierList();
    },
    handleBuyerCurrentChange(val) {
      this.formChildData.page = val;
      this.currentlistPage = val;
      this.buyerSupplierList();
    },
    handleEdit(index, row) {
      this.$confirm(
        row.state === 1 ? "确定要禁用吗？" : "确定要启用吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.general
            .buyerStatus({
              id: row.id,
              state: row.state === 1 ? 2 : 1,
            })
            .then((res) => {
              console.log(res, "禁用或者启用");
              this.$message({
                type: "success",
                message: row.state === 1 ? "禁用成功" : "启用成功",
              });
              this.getTableData();
            });
        })
        .catch(() => {});
    },

    selectda(index, row) {
      this.title = "编辑业务商";
      this.ruleForm.business_type_code = row.business_type_code;
      //修改参数显示不全，问题
      // if (row.business_type_code == "logistics") {
      //   this.list.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // } else {
      //   this.list1.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // }
      console.log(this.ruleForm, this.list, this.list);
      this.centerDialogVisible = true;
      // this.shownode = false;
      // this.$api.shipper.shipperinfo({ id: row.id }).then((res) => {
      //   console.log(res, "获取数据");
      //   this.info = res.data;
      //
      // });
    },
    /**
     * 获取列表数据
     */
    getTableData() {
      this.loading = true;
      const params = {
        ...this.formData,
      };
      this.$api.general.buyerList(params).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    seletlist() {
      this.$api.login.unusedall().then((res) => {
        console.log(res, "获取所有可配置的自提点");
        this.list1 = res.data;
      });
      this.$api.login.logisticsunusedall().then((res) => {
        console.log(res, "获取所有可配置的城市仓");

        this.list = res.data;
      });
    },
  },
};
</script>
<style lang="scss">
.buyer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .categories_name_disabled {
    display: flex;
    align-items: center;
  }
 

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .content {
    padding: 30px 0;
    text-align: center;
    .el-select {
      width: 350px;
    }
  }
  .contents {
    padding: 23px 55px;
    box-sizing: border-box;
    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }
    .imgs {
      display: flex;
      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;
        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    .disply {
      display: flex;
      margin-bottom: 22px;
    }
    .mr {
      margin-right: 31px;
    }
    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }
      .el-input {
        width: 302px;
      }
    }
  }
}
.colors {
  color: #fa884c;
}
.times {
  margin-right: 8px;
}
// .el-picker-panel {
//   left: 1245px !important;
// }
</style>
